<template>
  <div class="about-contaner">
    <Header></Header>
    <Nav></Nav>
    <!-- 关于我 start -->
    <el-row class="about-box">
      <el-row class="contaner">
        <!-- 面包屑 start -->
        <el-row class="nav-box" type="flex" align="middle">
          <span class="pos">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>关于我们</el-breadcrumb-item>
          </el-breadcrumb>
        </el-row>
        <!-- 面包屑 end -->
        <!-- 关于我们内容 start -->
        <el-row class="about">
          <p class="title">关于我们</p>
          <p class="desc" v-html="showText"></p>
        </el-row>
        <!-- 关于我们内容 end -->
      </el-row>
    </el-row>
    <Footer></Footer>
    <tell-us transform="translateY(-50%)" top="50%"></tell-us>

    <!-- 右侧保修 客服 -->
    <!-- <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row> -->
    <!-- 弹窗 start -->
    <!-- <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog> -->
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
export default {
  components: {
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    return {
      assderVisible: false, //客服弹窗

      showText: "", //隐私政策
    };
  },

  mounted() {},

  created() {
    //根据分组查询系统配置
    this.getConfig();
  },

  methods: {
    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "aboutUs",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showText = res.data.aboutUs;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 控制客服弹窗
    assderHandle() {
      this.assderVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.about-contaner {
  width: 100%;
  height: 100%;
  .about-box {
    min-width: 1200px;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

    background: #f8f8f8;
    .contaner {
      background: #f8f8f8;
    }
    .nav-box {
      .pos {
        color: #333333;
        font-size: 16px;
      }
    }
  }
  .about {
    min-height: 602px;
    width: 100%;
    margin-top: 32px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 32px;
    .title {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 35px;
    }
    .desc {
      font-size: 18px;
      color: #666666;
    }
  }
}
// 右侧保修 客服
// .fix-right {
//   cursor: pointer;
//   width: 88px;
//   border: 1px solid #ebebeb;
//   position: fixed;
//   z-index: 1000;
//   right: 0;
//   // bottom: 266px;
//   top: 50%;
//   transform: translateY(-50%);
//   .item1 {
//     background-color: #ffffff;
//   }
//   .item2 {
//     background-color: #ed1b23;
//     z-index: 999;
//   }
//   .item1,
//   .item2 {
//     height: 90px;
//     text-align: center;
//     box-sizing: border-box;
//     padding-top: 9px;
//     // padding-bottom: 16px;
//     img {
//       width: 24px;
//       height: 24px;
//     }
//     p {
//       color: #757575;
//       font-size: 14px;
//       margin-top: 12px;
//     }
//   }
//   .item2 p {
//     color: #ffffff;
//   }
// }
// 自定义弹窗样式
// .assderTc {
//   display: flex;
//   align-items: center;
//   .title {
//     padding-top: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     font-size: 22px;
//     font-weight: bold;
//     color: #333333;
//     img {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       top: 32px;
//       right: 32px;
//     }
//   }
//   ::v-deep .el-dialog__header {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 0 !important;
//   }
//   ::v-deep .el-dialog__body {
//     padding: 0;
//   }
//   ::v-deep .el-dialog__footer {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 10px;
//   }
//   .ja-c {
//     height: 311px;
//     box-sizing: border-box;
//     padding-left: 214px;
//     padding-top: 52px;
//     p {
//       display: flex;
//       align-items: center;
//       margin-bottom: 32px;
//       img {
//         width: 30px;
//         height: 30px;
//       }
//       span {
//         font-size: 14px;
//         color: #333333;
//         margin-left: 16px;
//       }
//     }
//   }

//   .btnad {
//     width: 112px;
//     height: 40px;
//     background: rgba(246, 247, 249, 1);
//     border: 1px solid #ecedef;
//     opacity: 1;
//     border-radius: 20px;
//   }
//   .btnsad {
//     color: #333;
//     width: 112px;
//     height: 40px;
//     background: rgba(255, 195, 44, 1);
//     opacity: 1;
//     border-radius: 20px;
//   }
// }
</style>